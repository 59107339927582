<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<h5 class="mb-4">
								<strong>Nuevo registro de usuario</strong>
							</h5>
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<insertOrUpdateUsers />
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
/**
 * @module System/Users/Create
 * @description - Purchase order management module for the workshop
 * @version 1.0
 * <pre>
 * CHANGELOG:
 *     20210201: Initial module integration
 * EMITS:
 * </pre>
 **/

import insertOrUpdateUsers from '@/components/insertOrUpdateUsers'

export default {
	name: 'CreateUsers',
	components: {
		insertOrUpdateUsers,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		spinnerLoaderLabel() {
			return this.$store.state.users.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.users.spinnerLoader
		},
	},
	beforeCreate() { },
	created() { },
	beforeMount() { },
	mounted() { },
	beforeUpdate() { },
	updated() { },
	beforeDestroy() { },
	destroyed() { },
	methods: {},
	watch: {},
}
</script>